import styled from 'styled-components';
import { useState } from 'react';

import { Location } from 'features/location/Location';

import { suggestedSearches } from 'components/PageWrappers/SharedComponents/GlobalSearchModal/suggestedSearches';
import { BaseSearch } from 'components/Toolkit/SearchModal/BaseSearch';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { SearchModal } from 'components/Toolkit/SearchModal/SearchModal';

import { useLocationWriteState } from 'features/location/hooks/LocationWriteState/LocationWriteState.hook';

import type { IHandleSelectSearchItem } from 'components/Toolkit/SearchModal/BaseSearch';
import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';
import type { IAutoCompleteSuggestion } from 'types';

import { Desktop } from 'components/Support/Desktop/Desktop';
import { Mobile } from 'components/Support/Mobile/Mobile';

import type { DisplayContainerConfig } from 'components/Support/DisplayContainer/DisplayContainer';

export interface VerticalSearchProps {
  baseUrl: string;
  query: string;
  onInput: (t: string) => void;
  onSearch: (t: string) => void;
  onHandleSelectSearchItem: ({
    autoSuggestedText,
    searchRequest,
    url,
    category,
  }: IHandleSelectSearchItem) => void;
  autoSuggestValues?: IAutoCompleteSuggestion[];
  lastSearchData?: TLastSearch;
  lastSearchDataTrackingValue?: string;

  searchPlaceholder: string;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(2, 48px);
  gap: ${({ theme }) => theme.spacing.M16};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

function VerticalSearch({
  baseUrl,
  query,
  onInput,
  onSearch,
  onHandleSelectSearchItem,
  autoSuggestValues,
  lastSearchData,
  lastSearchDataTrackingValue,
  searchPlaceholder,
}: VerticalSearchProps) {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);

  const { handleSelectListItem: onSelect } = useLocationWriteState();

  const bottomSheetConfig: DisplayContainerConfig = {
    small: 'block',
    medium: 'none',
    large: 'none',
  };
  const dropdownConfig: DisplayContainerConfig = {
    small: 'none',
    medium: 'block',
    large: 'block',
  };

  return (
    <Container>
      <Mobile>
        <InputButton
          isActive={isSearchModalOpen}
          value={query}
          onClick={() => setIsSearchModalOpen(true)}
          placeholder={searchPlaceholder}
          variant="SEARCH"
        />
      </Mobile>
      <Desktop>
        <BaseSearch
          variant="INLINE"
          onInput={onInput}
          onHandleSearch={onSearch}
          onHandleSelectSearchItem={onHandleSelectSearchItem}
          initialSearchValues={suggestedSearches(baseUrl)}
          placeholder="Search DoneDeal"
          autoSuggestItems={autoSuggestValues}
          styles={{ borderRight: 'none' }}
          lastSearchData={lastSearchData}
          lastSearchDataTrackingValue={lastSearchDataTrackingValue}
        />
      </Desktop>
      <Location
        skeletonListLength={16}
        maxHeight="376px"
        secondarySelectWidth="116px"
        maxTextContainerWidthMWeb="120px"
        onSelect={onSelect}
        bottomSheetConfig={bottomSheetConfig}
        dropdownConfig={dropdownConfig}
        heightVariant="LARGE"
      />
      {isSearchModalOpen && (
        <SearchModal
          askToClose={() => setIsSearchModalOpen(false)}
          isOpen={isSearchModalOpen}
          placeholder="Search Farming"
          presetInput={query}
          onInput={onInput}
          onSearch={onSearch}
          onHandleSelectSearchItem={onHandleSelectSearchItem}
          initialSearchValues={suggestedSearches(baseUrl)}
          autoSuggestItems={autoSuggestValues}
          lastSearchData={lastSearchData}
        />
      )}
    </Container>
  );
}

export { VerticalSearch };
