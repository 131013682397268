import styled, { useTheme } from 'styled-components';
import Modal, { Props } from 'react-modal';
import { media } from 'helpers/breakpoints';

import {
  BaseSearch,
  IHandleSelectSearchItem,
} from 'components/Toolkit/SearchModal/BaseSearch';
import { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';
import type { IAutoCompleteSuggestion } from 'types';
import { withOpacity } from 'helpers/Theme/Theme';

export interface SearchModalProps extends Props {
  askToClose: () => void;
  placeholder?: string;
  presetInput?: string;
  onFocus?: () => void;
  onSearch: (t: string) => void;
  onInput: (t: string) => void;
  id?: string;
  name?: string;
  autoSuggestItems?: IAutoCompleteSuggestion[];
  autoSuggestDisplayCount?: number;
  onHandleSelectSearchItem: ({
    autoSuggestedText,
    searchRequest,
    url,
    category,
    tracking,
    searchType,
  }: IHandleSelectSearchItem) => void;
  initialSearchValues?: IAutoCompleteSuggestion[];
  tracking?: string;
  lastSearchData?: TLastSearch;
  lastSearchDataTrackingValue?: string;
}

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#__next');

const SModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9950;
  outline: none;

  &:only-child {
    ${media.large} {
      position: relative;
      top: 96px;
      width: 712px;
      height: initial;
      margin: 0 auto;
    }
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: ${({ theme }) => theme.getOuterShadow(theme.colors.BLACK)};
  -webkit-overflow-scrolling: touch;

  ${media.large} {
    border-radius: 8px;
  }
`;

function SearchModal({
  isOpen,
  askToClose,
  placeholder,
  presetInput,
  onFocus,
  onSearch,
  onInput,
  onHandleSelectSearchItem,
  autoSuggestItems,
  autoSuggestDisplayCount = 10,
  initialSearchValues,
  tracking,
  lastSearchData,
  lastSearchDataTrackingValue,
}: SearchModalProps) {
  const theme = useTheme();

  return (
    <SModal
      isOpen={isOpen}
      onRequestClose={askToClose}
      style={{
        overlay: {
          zIndex: 9950,
          backgroundColor: withOpacity(theme.colors.GREY_DARKER, 0.8),
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      }}
    >
      <ModalWrapper>
        <BaseSearch
          variant="MODAL"
          placeholder={placeholder}
          presetInput={presetInput}
          initialSearchValues={initialSearchValues}
          autoSuggestItems={autoSuggestItems}
          autoSuggestDisplayCount={autoSuggestDisplayCount}
          onHandleSelectSearchItem={onHandleSelectSearchItem}
          onFocus={onFocus}
          onInput={onInput}
          onHandleSearch={onSearch}
          autoFocus={true}
          onClose={askToClose}
          tracking={tracking}
          lastSearchData={lastSearchData}
          lastSearchDataTrackingValue={lastSearchDataTrackingValue}
        />
      </ModalWrapper>
    </SModal>
  );
}

export { SearchModal };
